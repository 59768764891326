import { browser, version } from '$app/environment';
import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

/** @type {BrowserAgent | null} */
export let newrelicBrowserAgent = null;

/** @type {{ debtor?: string, role?: string, roles?: string[] } | null} */
let currentUser = null;

export const track = {
	/**
	 * @param {Error | string} error
	 * @param {object} customProperties
	 */
	exception(error, customProperties) {
		customProperties = {
			...customProperties,
			user: currentUser,
		};
		if (newrelicBrowserAgent === null) {
			console.log('[NewRelic][track:exception] %o %o', error, customProperties);
			return;
		}
		return newrelicBrowserAgent.noticeError(error, customProperties);
	},
	/** @param {import('@ctm/auth').UserInfo | null} user */
	user(user = null) {
		if (user) {
			if (currentUser !== null) {
				return;
			}
			newrelicBrowserAgent?.setUserId(user.id);
			currentUser = {
				debtor: user.debtor,
				role: user.roleTypeName,
				roles: user.roles,
			};
		} else {
			newrelicBrowserAgent?.setUserId(null);
			currentUser = null;
		}
	},
};

export function setupNewRelic() {
	if (!browser) {
		return;
	}

	const applicationID = environmentSettings.newRelicApplicationID;
	if (!applicationID) {
		console.warn('[NewRelic] no applicationID in environment settings');
		return;
	}

	const apiUrl = new URL(environmentSettings.apiUrl);
	const options = {
		init: {
			distributed_tracing: {
				enabled: true,
				cors_use_newrelic_header: false,
				cors_use_tracecontext_headers: true,
				allowed_origins: [apiUrl.origin],
			},
			privacy: { cookies_enabled: true },
			ajax: { deny_list: ['bam.nr-data.net'] },
            feature_flags: ['soft_nav'],
		},
		info: { beacon: 'bam.nr-data.net', errorBeacon: 'bam.nr-data.net', licenseKey: 'NRJS-548daa526257d90f2c0', applicationID, sa: 1 },
		loader_config: { accountID: '4475278', trustKey: '4475278', agentID: applicationID, licenseKey: 'NRJS-548daa526257d90f2c0', applicationID },
	};

	newrelicBrowserAgent = new BrowserAgent(options);

	newrelicBrowserAgent.setErrorHandler(function (err) {
		if (typeof err === 'string') {
			return false;
		}

		// ignore "Script error" which are caused by external
		// scripts and contain no info in New Relic
		if (err?.message === 'Script error.') {
			return true;
		}

		// ignore random 404 requests that are appearing in new relic
		// @ts-expect-error: non-standard `text` property on some errors
		if (err?.text === 'Not Found' && err?.message.includes('&ptid')) {
			return true;
		}

		// ignore "Network Error" as this is usually
		// caused by fault on the client side
		if (err?.message?.includes('Network Error')) {
			return true;
		}

		return false;
	});

	newrelicBrowserAgent.setApplicationVersion(version);
}
